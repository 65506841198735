<template>
    <div class="ht-form ht-switch">
        <div
            :class="[
                'inline-switch',
                label ? 'ht-switch-label' : 'ht-switch-no-label'
            ]"
        >
            <div class="ht-form-label">
                {{ label }}
                <span
                    v-if="!isRequired && showOptional"
                    class="ht-form-optional"
                >
                    <t>Optional</t>
                </span>
                <span v-if="isRequired && showAsterisk">*</span>
            </div>
            <div class="ht-form-body d-flex align-items-center">
                <fieldset :data-cy="cypress">
                    <input
                        type="checkbox"
                        :checked="value"
                        :disabled="disabled"
                    >

                    <div
                        class="switch"
                        @click="event => !disabled && $emit('input', !value)"
                    >
                        <div class="label on-label">
                            {{ onLabel }}
                        </div>
                        <div class="label off-label">
                            {{ offLabel }}
                        </div>
                        <div
                            class="switch-visual"
                            :class="isBranded ? 'branding-bg' : ''"
                        >
                            <!-- The actual visual of the switch, done in CSS on the :after pseudo element -->
                        </div>
                    </div>
                </fieldset>
                <span
                    v-if="tooltip"
                    v-tooltip.right="tooltip"
                    class="ml-3 cursor-help"
                >
                    <FontAwesomeIcon icon="info-circle" />
                </span>
            </div>
        </div>
        <div
            v-if="error"
            class="ht-form-error mt-3"
        >
            {{ error }}
        </div>
        <p
            v-if="description"
            class="ht-form-description"
        >
            {{ description }}
        </p>
    </div>
</template>

<script>
import HtFormMixin from './HtFormMixin';

export default {
    name: 'HtFormSwitch',
    mixins: [HtFormMixin],

    props: {
        value: {
            type: Boolean,
            default: false,
        },
        offLabel: {
            type: String,
            default: '',
        },
        onLabel: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        isBranded: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';

$switch-button-diameter: 2rem;
$switch-padding: 0.2rem;

.switch-visual {
    position: relative;
    width: $switch-button-diameter * 2;
    height: $switch-button-diameter + 2 * $switch-padding;
    border-radius: ($switch-button-diameter + 2 * $switch-padding) / 2;
    transition: $transition;

    &:after {
        content: '';
        height: $switch-button-diameter;
        width: $switch-button-diameter;
        border-radius: 50%;
        background: $color-white;
        box-shadow: 0 0 1px 0 rgba(black, 0.5), 0 2px 1px 0 rgba(black, 0.17);
        position: absolute;
        top: $switch-padding;
        left: $switch-padding;
        transition: $transition;
    }
}

.switch {
    cursor: pointer;
    display: flex;
    gap: 1.6rem;
    align-items: center;
}

input {
    display: none;

    &:checked + .switch {
        .switch-visual:after {
            transform: translateX(
                $switch-button-diameter * 2 -
                    ($switch-button-diameter + 2 * $switch-padding)
            );
        }

        .on-label {
            display: block;
        }
        .off-label {
            display: none;
        }
    }
}

input:checked + .switch .switch-visual:not(.branding-bg) {
    background: $green;
}

input:not(:checked) + .switch .switch-visual {
    background: $grey-ultra-light;
}

.on-label {
    display: none;
}
.label {
    font-family: $lato;
    font-size: 1.4rem;
    body:not(.old-ui) &{
        font-family: var(--poppins);
    }
}

.ht-switch {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.6rem;
}

.inline-switch {
    display: flex;
    align-items: center;

    &.ht-switch-label {
        justify-content: space-between;
    }
    &.ht-switch-no-label {
        justify-content: flex-end;
    }
}

input:disabled + .switch {
    cursor: default;

    .switch-visual {
        background-color: $grey-ultra-light !important;

        &:after {
            background: $grey;
        }
    }

    .label {
        color: $grey;
    }
}

</style>
