<template>
    <div class="ht-form">
        <div
            v-if="label"
            class="d-flex ht-form-label mb-3"
            :class="labelClass"
        >
            {{ label }}
            <span
                v-if="!isRequired"
                class="ht-form-optional"
            >
                <t>Optional</t>
            </span>
            <span
                v-if="isRequired && showAsterisk"
                class="ml-1"
            >*</span>
            <span
                class="filler filler-little"
            />
            <span
                v-if="tooltip"
                v-tooltip.right-end="tooltip"
                class="cursor-help"
            >
                <FontAwesomeIcon :icon="['fas', 'info-circle']" />
            </span>
            <div
                v-if="hasDeleteIcon"
                class="ht-form-delete branding-color"
                @click="$emit('onDelete')"
            >
                <FontAwesomeIcon :icon="['far', 'trash-alt']" />
                <t>Delete</t>
            </div>
        </div>
        <div
            v-if="description"
            class="ht-form-description mb-3"
        >
            {{ description }}
        </div>
        <div class="ht-form-body">
            <slot />
        </div>
        <div
            v-if="error"
            class="ht-form-error mt-3"
        >
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'HtFormGroup',
    inject: ['$validator'],

    props: {
        label: {
            type: [String, Boolean],
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        tooltip: {
            type: String,
            default: () => null,
        },
        error: {
            type: String,
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        showAsterisk: {
            type: Boolean,
            default: false,
        },
        hasDeleteIcon: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        labelClass() {
            const classes = [];

            if (this.error) {
                classes.push('error');
            }

            return classes;
        },
    },
};
</script>

<style lang="scss">
@import '~@/styles/var';

.ht-form {
    margin-bottom: 3rem;

    fieldset {
        margin-bottom: 0 !important;
    }

    &-body {
        position: relative;
    }

    &-label {
        font-size: 1.4rem;
        font-family: $lato-bold;
        body:not(.old-ui) &{
            font-family: var(--poppins);
            font-weight: 600;
        }
        color: $color-text;

        &.error {
            color: $primary-darker;
        }
    }

    &-description {
        font-size: 1.4rem;
        color: $grey-text;
    }

    &-optional {
        font-family: $lato-italic;
        body:not(.old-ui) &{
            font-family: var(--poppins);
        }
        color: $color-grey-lighter;
        margin: 0 0 0 5px;
    }

    &-error {
        font-size: 1.4rem;
        color: $primary-darker;
    }

    &-delete {
        font-size: 1.4rem;
        cursor: pointer;
    }

    .cursor-help {
        cursor: help !important;
    }
}

.filler {
    flex-grow: 1;
}

.filler-little {
    flex-grow: unset;
    margin-right: 0.75rem;
}
</style>
